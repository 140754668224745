import React from 'react';
import Seo from '../components/molecules/Seo';
import styled from 'styled-components';
import Image from '../components/atoms/Image';
import TwoColumn from '../components/organisms/TwoColumn';

const Holder = styled.div`
  p { ${props => props.theme.fluidType( 1 )}; }
`;

function AboutPage() {

  return (
    <>
      <Seo title="About" description="Unicorns Forever was inspired by 2 very, very small, crazy gnomes. They lived with their mother and father deep in a mountain star stream."/>
      <Holder>
        <TwoColumn>
          <div>
            <h1>About Unicorns Forever</h1>
            <p>Unicorns Forever was inspired by 2 very, very small, crazy gnomes. They lived with
              their
              mother and father deep in a mountain star stream. Their father (a
              very powerful computer program website wizard) saw the magic deep inside their heads.
              One
              day he decided to get that magic out and onto paper. To do this he used textas, very
              powerful computers and the newest computer programs. He saw the power of these magic
              diagrams and decided that there was only one place for these to go… on cute little
              T-shirts.</p>
          </div>
          <div>
            <Image imgName="gnomes.jpg"/>
            <small><em>2 very, very small gnomes</em></small>
          </div>
        </TwoColumn>

        <TwoColumn>
          <div>
            <Image imgName="mini-troll.jpg"/>
            <small><em>Lazy, young, swamp-dwelling mini-troll</em></small>
          </div>
          <div>
            <p>Many millions of gnome years later the kind wizard asked his young, lazy cousin, a
              swamp-dwelling mini-troll, to help him with the business. Though the mini-troll had no
              real training and worshiped the clear flat, reflective wishing pools that all young
              mini-trolls do, the wizard was kind and knew he could train the troll to become a
              valuable
              member of the team. The kindness of the wizard was rewarded. Over time the mini-troll
              came
              up with many fabulous ideas for new products and tools for customer engagement. The
              mini-troll delivered these products with speed and efficiency never before seen from a
              mini-troll and thus, the business flourished.</p>
          </div>
        </TwoColumn>

        <TwoColumn>
          <div>
            <p>As the artwork for the company is formed deep inside the brains of two very strange
              yet
              pure creatures it is very powerful. It heals sore knees, makes people smile, keeps the
              tea
              in tea parties flowing and brings fairies to your garden. It is also highly original,
              totally magic, unisex and above all FUN.</p>
          </div>
          <div>
            <Image imgName="super-wizard.jpg"/>
            <small><em>Powerful computer program website wizard</em></small>
          </div>
        </TwoColumn>


      </Holder>
    </>
  )
}

export default AboutPage;

